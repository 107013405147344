
.TextComponentWrapper{
    /*line-height: 1em !important;*/
    /*display: inline-block;*/
    /*line-height: 1 !important;*/
    /*height: auto;*/

    white-space: pre-wrap;
}

.TextComponentWrapper span {
    white-space: pre-wrap;
    word-break: keep-all;
}



.TextComponentWrapper .underline{
    text-decoration: underline;
}

.TextComponentWrapper .bold{
    font-weight: 600;
}

.TextComponentWrapper .italic{
    font-style: italic;
}

.TextComponentWrapper .strikethrough{
    text-decoration: line-through;
}

.TextComponentWrapper .fontSize8 {font-size: 08px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize9 {font-size: 09px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize10{font-size: 10px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize11{font-size: 11px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize12{font-size: 12px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize14{font-size: 14px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize16{font-size: 16px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize18{font-size: 18px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize20{font-size: 20px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize24{font-size: 24px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize30{font-size: 30px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize36{font-size: 36px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize48{font-size: 48px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize60{font-size: 60px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize72{font-size: 72px !important; line-height: 1.3em !important; height: auto;}
.TextComponentWrapper .fontSize96{font-size: 96px !important; line-height: 1.3em !important; height: auto;}

.TextComponentWrapper .FontArial{font-family: Arial;}
.TextComponentWrapper .FontGeorgia{font-family: Georgia;}
.TextComponentWrapper .FontHelvetica{font-family: Helvetica;}
.TextComponentWrapper .FontMonospace{font-family: Monospace;}
.TextComponentWrapper .FontTimesNewRoman{font-family: Times New Roman;}

.TextComponentWrapper .Color000000{color: #000000;}
.TextComponentWrapper .Color747474{color: #747474;}
.TextComponentWrapper .ColorC6C6C6{color: #C6C6C6;}
.TextComponentWrapper .ColorE7E7E7{color: #E7E7E7;}
.TextComponentWrapper .Color00AEEF{color: #00AEEF;}
.TextComponentWrapper .Color0072BC{color: #0072BC;}
.TextComponentWrapper .Color2E3192{color: #2E3192;}
.TextComponentWrapper .Color611DB7{color: #611DB7;}
.TextComponentWrapper .Color92278F{color: #92278F;}
.TextComponentWrapper .Color00A99D{color: #00A99D;}
.TextComponentWrapper .Color00A651{color: #00A651;}
.TextComponentWrapper .Color8DC63F{color: #8DC63F;}
.TextComponentWrapper .ColorED1C24{color: #ED1C24;}
.TextComponentWrapper .ColorED145B{color: #ED145B;}
.TextComponentWrapper .ColorF7941E{color: #F7941E;}
.TextComponentWrapper .ColorDFE312{color: #DFE312;}



.TextComponentWrapper .superscript8{ font-size: calc(08px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript9{ font-size: calc(09px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript10{font-size: calc(10px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript11{font-size: calc(11px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript12{font-size: calc(12px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript14{font-size: calc(14px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript16{font-size: calc(16px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript18{font-size: calc(18px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript20{font-size: calc(20px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript24{font-size: calc(24px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript30{font-size: calc(30px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript36{font-size: calc(36px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript48{font-size: calc(48px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript60{font-size: calc(60px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript72{font-size: calc(72px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}
.TextComponentWrapper .superscript96{font-size: calc(96px * 0.5) !important; vertical-align: top; line-height: 0.25em !important;}

.TextComponentWrapper .subscript8{ font-size: calc(08px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript9{ font-size: calc(09px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript10{font-size: calc(10px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript11{font-size: calc(11px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript12{font-size: calc(12px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript14{font-size: calc(14px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript16{font-size: calc(16px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript18{font-size: calc(18px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript20{font-size: calc(20px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript24{font-size: calc(24px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript30{font-size: calc(30px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript36{font-size: calc(36px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript48{font-size: calc(48px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript60{font-size: calc(60px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript72{font-size: calc(72px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}
.TextComponentWrapper .subscript96{font-size: calc(96px * 0.5) !important; vertical-align: sub; line-height: 0.25em !important;}

.TextComponentWrapper .variable{
    /*border:1px solid #FEFBDE;*/
    box-shadow: rgba(255, 212, 0, 0.9) 0px 0px 0px 1px inset;
    line-height: 1.25em;
    cursor: pointer;
}

.TextComponentWrapper .variable::selection {
    background: rgba(255, 212, 0, 0.9);
}


.TextComponentWrapper .focusedVariable {
    background: rgba(255, 212, 0, 0.9);
}

.TextComponentWrapper .link {
    text-decoration: underline !important;
    color: #2E3192;
    cursor: pointer;
}

